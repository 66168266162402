import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import { Typography, Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import '../styles/login.css';

const { Title } = Typography;

const Login: React.FC = () => {
  const [isLogin, setIsLogin] = useState(true); // Toggle between login and register
  const [transitionState, setTransitionState] = useState('enter'); // Animation state
  const navigate = useNavigate(); // Navigation hook

  const toggleForm = () => {
    setTransitionState('exit'); // Start the exit animation
    setTimeout(() => {
      setIsLogin((prevState) => !prevState); // Switch form after animation
      setTransitionState('enter'); // Start the enter animation
    }, 300); // Match the animation duration in CSS
  };

  const handleLogin = () => {
    // Simulated login action
    navigate('/dashboard');
  };

  return (
    <div className="login-page">
      {/* Back Arrow */}
      <Link to="/" className="back-arrow-container">
        <img src="/images/arrow.png" alt="Back to Home" className="back-arrow-icon" />
      </Link>

      {/* Animated Form Container */}
      <div className={`form-container ${transitionState}`}>
        <Title className="login-title">{isLogin ? 'Login' : 'Register'}</Title>
        <Form
          name={isLogin ? 'login-form' : 'register-form'}
          layout="vertical"
          className="login-form"
          onFinish={handleLogin} // Call handleLogin on form submission
        >
          {!isLogin && (
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Please enter your username!' }]}
            >
              <Input placeholder="Enter your username" />
            </Form.Item>
          )}

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Please enter your email!' },
              { type: 'email', message: 'Please enter a valid email!' },
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please enter your password!' }]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>

          {!isLogin && (
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                { required: true, message: 'Please confirm your password!' },
              ]}
            >
              <Input.Password placeholder="Confirm your password" />
            </Form.Item>
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit" className="form-button">
              {isLogin ? 'Login' : 'Register'}
            </Button>
          </Form.Item>
        </Form>

        <p className="toggle-text">
          {isLogin
            ? "Don't have an account? "
            : 'Already have an account? '}
          <span className="toggle-link" onClick={toggleForm}>
            {isLogin ? 'Register' : 'Login'}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
