import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import ProductSelection from './ProductSelection';
import ProductExplanation from './ProductExplanation'; // Updated import
import StatsCounter from './StatsCounter';  // Import your new counter component
import '../styles/homepage.css';

const { Content } = Layout;

const HomePage: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/stars.js'; // Path to the JS file
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Cleanup the script on unmount
    };
  }, []);

  return (
    <Layout className="homepage-layout">
            <canvas id="stars"></canvas>
      <Content className="homepage-content">
      {/* <Link to="/login" className="user-icon-container">
          <img src="/images/user.png" alt="Dashboard" className="user-icon" />
        </Link> */}
        <div className="homepage-title">SPIRIT GUARD</div>
        <div className="homepage-description">
        <br></br>FiveM's Leading AntiSpoof and preventive AntiCheat<br></br>Ban them once, keep them out for good
        </div>
        <StatsCounter />

        <ProductSelection />
        <ProductExplanation />
      </Content>
      <footer className="homepage-footer">
      <div className="f1">By xPiwel - Elmani335</div>
        <div className="f2">2025 | SPIRIT GUARD SYSTEMS - All rights reserved</div>
      </footer>
    </Layout>
  );
};

export default HomePage;
