import React, { useState } from 'react';
import '../styles/antispoof.css';

interface Player {
  id: number;
  name: string;
  ip: string;
  license: string;
  license2: string;
  hwid: string;
  steamId: string;
  fivemId: string;
  xboxLiveId: string;
}

const samplePlayers: Player[] = [
  {
    id: 1,
    name: 'PlayerOne',
    ip: '192.168.1.1',
    license: 'license_12345',
    license2: 'license2_67890',
    hwid: 'HWID_ABC123',
    steamId: 'STEAM_0:1:123456789',
    fivemId: '12345',
    xboxLiveId: 'Xbox_12345',
  },
  {
    id: 2,
    name: 'PlayerTwo',
    ip: '192.168.1.2',
    license: 'license_54321',
    license2: 'license2_09876',
    hwid: 'HWID_XYZ789',
    steamId: 'STEAM_0:1:987654321',
    fivemId: '54321',
    xboxLiveId: 'Xbox_54321',
  },
];

const AntiSpoof: React.FC = () => {
  const [players, setPlayers] = useState<Player[]>(samplePlayers);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredPlayers = players.filter((player) =>
    player.name.toLowerCase().includes(searchQuery)
  );

  const handlePlayerSelect = (player: Player) => {
    setSelectedPlayer(player);
  };

  const handleBan = (playerId: number) => {
    alert(`Player with ID ${playerId} has been banned!`);
    setPlayers(players.filter((player) => player.id !== playerId));
    setSelectedPlayer(null);
  };

  return (
    <div className="layout">
      <div className="content">
        <div className="antispoof-container">
          {/* Search Bar */}
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search player..."
              value={searchQuery}
              onChange={handleSearch}
              className="search-input"
            />
          </div>

          {/* Player List */}
          <div className="player-list">
            <h3>Connected Players</h3>
            <ul>
              {filteredPlayers.map((player) => (
                <li
                  key={player.id}
                  onClick={() => handlePlayerSelect(player)}
                  className={selectedPlayer?.id === player.id ? 'selected' : ''}
                >
                  {player.name}
                </li>
              ))}
            </ul>
          </div>

          {/* Player Details */}
          {selectedPlayer && (
            <div className="player-details">
              <h3>Player Details</h3>
              <p><strong>Name:</strong> {selectedPlayer.name}</p>
              <p><strong>IP:</strong> {selectedPlayer.ip}</p>
              <p><strong>License:</strong> {selectedPlayer.license}</p>
              <p><strong>License 2:</strong> {selectedPlayer.license2}</p>
              <p><strong>HWID:</strong> {selectedPlayer.hwid}</p>
              <p><strong>Steam ID:</strong> {selectedPlayer.steamId}</p>
              <p><strong>FiveM ID:</strong> {selectedPlayer.fivemId}</p>
              <p><strong>Xbox Live ID:</strong> {selectedPlayer.xboxLiveId}</p>
              <button
                className="ban-button"
                onClick={() => handleBan(selectedPlayer.id)}
              >
                Ban Player
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AntiSpoof;
