import React, { useEffect, useState } from 'react';
import '../styles/statsCounter.css';

type Stat = {
    id: number;
    target: number;
    suffix?: string;
    label: string;
  };
  
  const stats: Stat[] = [
    { id: 1, target: 128000, suffix: '+', label: 'blocked botnet' },
    { id: 2, target: 67500, suffix: '+', label: 'user scanned every hour' },
    { id: 3, target: 53800, suffix: '+', label: 'automatic ban' },
    { id: 4, target: 270000, suffix: '+', label: 'trust vector' },
    { id: 5, target: 4000000, suffix: '+', label: 'data analyzed every hour' },
    { id: 6, target: 8000, suffix: '+', label: 'Users protected every day' },
    { id: 7, target: 56000, suffix: '+', label: 'blocked VPNs' },
    { id: 8, target: 400, suffix: '+', label: 'Active players' },
  ];
  
  const AnimatedCounter: React.FC<{ target: number; suffix?: string }> = ({ target, suffix }) => {
    const [count, setCount] = useState(0);
  
    useEffect(() => {
      let start = 0;
      const duration = 2000; // Total animation time in milliseconds
      const intervalTime = 10; // Update every 100ms so the slide effect is visible
      const step = target / (duration / intervalTime);
      const timer = setInterval(() => {
        start += step;
        if (start >= target) {
          start = target;
          clearInterval(timer);
        }
        setCount(Math.floor(start));
      }, intervalTime);
      return () => clearInterval(timer);
    }, [target]);
  
    return (
      <span key={count} className="slide-in">
        {count.toLocaleString('fr-FR')}
        {suffix}
      </span>
    );
  };
  
  const StatsGrid: React.FC = () => {
    return (
      <div className="stats-grid-container">
        {stats.map((stat) => (
          <div key={stat.id} className={`stat-item-${stat.id}`}>
            <div className="stat-number">
              
              <AnimatedCounter target={stat.target} suffix={stat.suffix} />
            </div>
            <div className="stat-label">{stat.label}</div>
          </div>
          
        ))}
      </div>
    );
  };
  
  export default StatsGrid;