import React from 'react';
import { ConfigProvider, theme } from 'antd';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './components/HomePage';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import AntiSpoof from './components/AntiSpoof';
import Status from './components/Status';

const App: React.FC = () => {
  return (
    <Router>
      <Layout>
        <Routes>
        <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/anti-spoof" element={<AntiSpoof />} />
          <Route path="/status" element={<Status />} />

        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
