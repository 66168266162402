import React from 'react';
import { Card, Col, Row } from 'antd';
import '../styles/productsection.css'; // Import the new CSS file

type Product = {
  title: string;
  price: string;
  line: string;
  line2: string;
  features: string[];
  customClass: string;
};

const products: Product[] = [
  {
    title: 'Lite',
    price: '€ -.- /month',
    line: '(-% if less than 50 players)',
    line2: 'Get started with essential protection for small-scale servers.',
    features: [
      'Advanced Spoof Detection',
      'Entry Anti cheat',
      'Anti-Find-Trigger',
      'Entry Logs',
      '1 server IP - (1 live)',
      'Standard Web Panel',
      'Light DB Access',
      'Priority support 48h',
      'Automated Install',
    ],
    customClass: 'card-lite', // Custom class for Lite card
  },
  {
    title: 'Intercept',
    price: '€-.- / month',
    line: '(-% if less than 50 players)',
    line2: 'Boost your server security with enhanced features.',
    features: [
      'Advanced Spoof Detection',
      'Pro Anti cheat',
      'Anti-Find-Trigger',
      'Extended Logs',
      '2 server IP - (1 live + 1 dev)',
      'Advanced Live Web Panel',
      'Standard DB Access',
      'Priority support 48h',
      'Automated Install',
    ],
    customClass: 'card-intercept', // Custom class for Intercept card
  },
  {
    title: 'SentryX',
    price: '€-.- / month',
    line: '(-% if less than 50 players)',
    line2: 'The ultimate choice for professional grade server security.',
    features: [
      'Advanced Spoof Detection',
      'SentryX Anti cheat',
      'Anti-Find-Trigger',
      'Complete Logs',
      '4 server IP - (2 live + 2 dev)',
      'Live Web Panel + Discord Bot',
      'Extended DB Access',
      'Priority support 24h',
      'Custom Install by our expert*',
    ],
    customClass: 'card-sentryx', // Custom class for SentryX Shield card
  },
];

const ProductSelection: React.FC = () => {
  return (
    <div className="product-selection-container">
      <Row gutter={[32, 32]} justify="center">
        {products.map((product, index) => (
          <Col xs={24} sm={12} md={8} key={index}>
            <Card className={`card ${product.customClass}`}>
              <div className="product-title">{product.title}</div>
              <p className="product-price">{product.price}</p>
              <p className="product-line">{product.line}</p>
              <p className="product-line2">{product.line2}</p>
              <ul className="product-features">
                {product.features.map((feature, idx) => (
                  <li key={idx} className="product-feature">
                    {feature}
                  </li>
                ))}
              </ul>
              <a href="https://discord.gg/hUAwMyQRxp">
              <div className="ant-btn">Contact us</div>
              </a>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ProductSelection;
