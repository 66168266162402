import React from 'react';
import '../styles/dashboard.css';
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Legend,
  Tooltip,
  ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// Register Chart.js components
ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Legend, Tooltip);

const Dashboard: React.FC = () => {
  const graphData = {
    labels: [
      '00h00', '4 AM', '8 AM', '12 PM', '4 PM', '8 PM', // Day 1
      '00h00', '4 AM', '8 AM', '12 PM', '4 PM', '8 PM', // Day 2
      '00h00', '4 AM', '8 AM', '12 PM', '4 PM', '8 PM', // Day 3
    ],
    datasets: [
      {
        label: 'Active Players',
        data: Array.from({ length: 18 }, () => Math.floor(Math.random() * 30) + 5),
        borderColor: '#6a0dad',
        backgroundColor: 'rgba(106, 13, 173, 0.3)',
        tension: 0.4,
        fill: true,
      },
    ],
  };

  const graphOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          color: 'white',
        },
      },
    },
    scales: {
      x: {
        ticks: { color: 'white' },
        grid: { display: false },
      },
      y: {
        ticks: { color: 'white' },
        grid: { color: 'rgba(255, 255, 255, 0.1)' },
      },
    },
  };

  return (
    <main className="content">
      <div className="stats-row">
        <div className="stat-card">
          <h3>Total Bans</h3>
          <p>134</p>
        </div>
        <div className="stat-card">
          <h3>AntiSpoof Blocks</h3>
          <p>57</p>
        </div>
        <div className="stat-card">
          <h3>Players</h3>
          <p>89</p>
        </div>
        <div className="stat-card">
          <h3>Connection Requests</h3>
          <p>230</p>
        </div>
      </div>
      <div className="content-container">
        <h2>Player Activity</h2>
        <div className="chart-container">
          <Line data={graphData} options={graphOptions} />
        </div>
      </div>
    </main>
  );
};

export default Dashboard;
