import React from 'react';
import '../styles/productexplanation.css';

type FAQ = {
  question: string;
  answer: string;
};

const faqs: FAQ[] = [
    {
      question: "What is Spirit Guard ?",
      answer: "Spirit Guard is FiveM's ultimate AntiSpoof system designed to keep cheaters permanently off your server.",
    },
    {
      question: "How do you block players who’ve never joined the server ?",
      answer: "Our powerful algorithm checks across all our databases to ensure that any malicious players are blocked before they can even join the server.",
    },
    {
      question: "How does spoof detection work ?",
      answer: "Our advanced spoof detection algorithm identifies and blocks attempts to bypass bans effectively and efficiently.",
    },
    {
      question: "Do I need technical knowledge to install it ?",
      answer: "Not at all! Our plans include automated installation, and premium plans offer expert setup assistance if needed!",
    },
    {
      question: "Can I manage everything online ?",
      answer: "Yes! Spirit Guard comes with a user-friendly web panel that allows you to manage logs, configurations, bans, and more!",
    },
    {
    question: "Are there any hidden fees ?",
      answer: "No! Our pricing is transparent, if you’re not satisfied with our service you can cancel at any time.",
    },
    {
      question: "Can you block VPNs ?",
        answer: "Yes! Spirit Guard has a built-in VPN detection system that blocks VPN users from joining your server.",
    },
    {
      question: "more questions ?",
        answer: "Contact us on our discord ! Our developers are always available to help you with any questions you may have.",
    },
  ];
  

const ProductExplanation: React.FC = () => {
  return (
    <div className="product-faq-container">
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item-container ${index % 2 === 0 ? "left" : "right"}`}
          >
            <div className="faq-item">
              <div className="faq-question">{faq.question}</div>
              <div className="faq-answer">{faq.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductExplanation;
