import React from 'react';
import ReactFlow, {
  Background,
  useNodesState,
  useEdgesState,
  getBezierPath,
  EdgeProps,
  Position,
} from 'reactflow';
import 'reactflow/dist/style.css';
import '../styles/status.css';

const proOptions = { hideAttribution: true };


// Animated edge with bezier path, animated line, and animated ball
const AnimatedEdge: React.FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition = Position.Right,
  targetPosition = Position.Left,
  style = {},
}) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      {/* Animated line */}
      <path
        id={id}
        d={edgePath}
        style={{
          stroke: '#6a0dad',
          strokeWidth: 2,
          fill: 'none',
          strokeDasharray: '0', // Dotted pattern
          animation: 'solid 2s linear infinite', // Animation for the dashes
          ...style,
        }}
      />
{/* Animated balls */}
<circle r="2" fill="#9213e7">
  <animateMotion dur="2s" repeatCount="indefinite" begin="0s">
    <mpath href={`#${id}`} />
  </animateMotion>
</circle>
<circle r="2" fill="#9213e7">
  <animateMotion dur="2s" repeatCount="indefinite" begin="0.5s"> 
    <mpath href={`#${id}`} />
  </animateMotion>
</circle>
<circle r="2" fill="#9213e7">
  <animateMotion dur="2s" repeatCount="indefinite" begin="1s"> 
    <mpath href={`#${id}`} />
  </animateMotion>
</circle>
<circle r="2" fill="#9213e7">
  <animateMotion dur="2s" repeatCount="indefinite" begin="1.5s"> 
  </animateMotion>
</circle>
<circle r="2" fill="#9213e7">
  <animateMotion dur="2s" repeatCount="indefinite" begin="2s"> 
    <mpath href={`#${id}`} />
  </animateMotion>
</circle>
<circle r="2" fill="#9213e7">
  <animateMotion dur="2s" repeatCount="indefinite" begin="2.5s"> 
    <mpath href={`#${id}`} />
  </animateMotion>
</circle>

    </>
  );
};

const Status: React.FC = () => {
  const [nodes] = useNodesState([
    {
      id: '1',
      position: { x: 50, y: 200 },
      data: { label: 'Your Server' },
      sourcePosition: Position.Right,
    },
    {
      id: '2',
      position: { x: 300, y: 200 },
      data: { label: 'SpiritGuard LB' },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: '3',
      position: { x: 550, y: 150 },
      data: { label: 'PROXY #2' },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: '4',
      position: { x: 550, y: 250 },
      data: { label: 'PROXY #1' },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: '5',
      position: { x: 800, y: 100 },
      data: { label: 'SP-Guard API' },
      targetPosition: Position.Left,
    },
    {
      id: '6',
      position: { x: 800, y: 200 },
      data: { label: 'DataBase' },
      targetPosition: Position.Left,
    },
    {
        id: '7',
        position: { x: 800, y: 300 },
        data: { label: 'Sentry' },
        targetPosition: Position.Left,
    },
    {
        id: '8',
        position: { x: 550, y: 80 },
        data: { label: 'Backup Proxy' },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,

    },
    {
        id: '9',
        position: { x: 800, y: 30 },
        data: { label: 'Backup API' },
        targetPosition: Position.Left,
    },
  ]);

  const [edges] = useEdgesState([
    { id: 'e1-2', source: '1', target: '2', type: 'animated' },
    { id: 'e2-3', source: '2', target: '3', type: 'animated' },
    { id: 'e2-4', source: '2', target: '4', type: 'animated' },
    { id: 'e2-8', source: '2', target: '8', type: 'animated' },
    { id: 'e3-5', source: '3', target: '5', type: 'animated' },
    { id: 'e3-6', source: '3', target: '6', type: 'animated' },
    { id: 'e3-7', source: '3', target: '7', type: 'animated' },
    { id: 'e4-5', source: '4', target: '5', type: 'animated' },
    { id: 'e4-6', source: '4', target: '6', type: 'animated' },
    { id: 'e4-7', source: '4', target: '7', type: 'animated' },
    { id: 'e8-9', source: '8', target: '9', type: 'animated' },


  ]);

  return (
    <div className="status-layout">
      <h1 className="status-title">System Status</h1>
      <ReactFlow
        proOptions={proOptions}
        nodes={nodes}
        edges={edges}
        fitView
        panOnDrag={false} // Disable panning
        zoomOnScroll={false} // Disable zoom on scroll
        zoomOnPinch={false} // Disable zoom on pinch gestures
        zoomOnDoubleClick={false} // Disable zoom on double click
        nodesDraggable={false} // Disable dragging nodes
        nodesConnectable={false} // Disable connecting nodes
        edgeTypes={{ animated: AnimatedEdge }} // Use animated edges
      >
        <Background color="#aaa" gap={16} />
      </ReactFlow>
    </div>
  );
};

export default Status;
