import React from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import '../styles/layout.css';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();

  const hideSidebarRoutes = ['/', '/login'];

  const shouldHideSidebar = hideSidebarRoutes.includes(location.pathname);

  return (
    <div className="layout">
      {!shouldHideSidebar && <Sidebar />}
      <div className={`main-content ${shouldHideSidebar ? 'full-width' : ''}`}>
        {children}
      </div>
    </div>
  );
};

export default Layout;
