import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/sidebar.css';
import DashboardIcon from '../assets/icons/dashboard.svg';
import DatabaseIcon from '../assets/icons/database.svg';
import LogsIcon from '../assets/icons/logs.svg';
import SupportIcon from '../assets/icons/support.svg';
import ShieldOkIcon from '../assets/icons/shield-ok.svg';
import RefreshIcon from '../assets/icons/refresh.svg';
import StatusIcon from '../assets/icons/status.svg';
import LogoutIcon from '../assets/icons/logout.svg';

const Sidebar: React.FC = () => {
  const navigate = useNavigate();

  const menuItems = [
    { key: 'dashboard', label: 'Dashboard', icon: DashboardIcon, route: '/dashboard' },
    { key: 'anti-spoof', label: 'Anti Spoof', icon: RefreshIcon, route: '/anti-spoof' },
    { key: 'anti-cheat', label: 'Anti Cheat', icon: ShieldOkIcon, route: '/anti-cheat' },
    { key: 'database', label: 'Database', icon: DatabaseIcon, route: '/database' },
    { key: 'logs', label: 'Logs', icon: LogsIcon, route: '/logs' },
    { key: 'support', label: 'Support', icon: SupportIcon, route: '/support' },
    { key: 'status', label: 'Status', icon: StatusIcon, route: '/status' },
    { key: 'logout', label: 'Logout', icon: LogoutIcon, route: '/' },
  ];

  return (
    <aside className="sidebar">
      <div className="logo">SPIRIT GUARD</div>
      <span className='tadm'>THIS IS A DEMO PAGE</span>

      <ul className="menu">
        {menuItems.map((item) => (
          <li
            key={item.key}
            className="menu-item"
            onClick={() => navigate(item.route)}
          >
            <img src={item.icon} alt={item.label} className="menu-icon" />
            <span className="label">{item.label}</span>
          </li>
          
        ))}
      </ul>
    </aside>
  );
};

export default Sidebar;
